module.exports = [{
      plugin: require('/opt/build/repo/client/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/client/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-128386622-1","head":false,"exclude":["/preview/**","/do-not-track/me/too/"],"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"poptopcanning.com"},
    },{
      plugin: require('/opt/build/repo/client/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
